import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Filmstrtip from '../../images/filmstrip.svg'
import IconCheck from '../../svgs/check-circle.inline.svg'

function PageBroker({ data }) {
  return (
    <Layout flush={true}>
      <Seo title="Broker" />
      <div className="bg-purple-200 pb-10 lg:pb-0 lg:mt-24 mb-16">
        <div className="container flex flex-col lg:flex-row relative">
          <div
            className="-mx-4 lg:mx-0 sm:order-2 lg:absolute right-0 z-20 lg:w-1/2 transform lg:-translate-y-1/2"
            style={{
              top: `50%`,
            }}
          >
            <Img className="shadow" fluid={data.house.childImageSharp.fluid} />
          </div>
          <div className="text-center lg:text-left py-10 lg:py-20 lg:w-1/2 lg:pr-24">
            <h1 className="text-3xl lg:text-5xl leading-tight mb-6 lg:mb-10 text-purple-900">
              Video is everywhere.
            </h1>
            <p className="md:text-xl mb-10">
              It&apos;s what converts. On Facebook. On Instagram. On YouTube.
              And in real estate. But until now it&apos;s taken work and cost
              money. Until now.
            </p>
            <a
              href="https://app.realestateshows.com/listings/preview"
              className="btn btn-purple transform hover:-translate-y-1"
            >
              Take it for a spin &rarr;
            </a>
          </div>
        </div>
      </div>

      <div className="container max-w-4xl text-center py-10">
        <div className="w-32 h-1 bg-purple-200 mx-auto mb-6 lg:mb-10"></div>
        <p className="text-xl lg:text-2xl">
          Imagine leveraging technology to help every one of your agents close
          more deals. That&apos;s the promise that software - front office and
          back office - has been making for years. But over the last five years
          everything in the software world has changed.
        </p>
        <div className="w-32 h-1 bg-purple-200 mx-auto mt-6 lg:mt-10"></div>
      </div>

      <div className="container py-20 lg:pb-32">
        <ul className="grid items-center grid-cols-1 md:grid-cols-2 md:row-gap-24 gap-10">
          <li className="md:col-start-2">
            <Img
              className="mx-auto"
              fluid={data.automatic.childImageSharp.fluid}
              style={{
                maxWidth:
                  data.automatic.childImageSharp.fluid.presentationWidth,
              }}
            />
          </li>
          <li className="text-center md:text-left md:col-start-1 md:row-start-1 text-lg md:text-xl mb-10 md:mb-0">
            You&apos;ve heard the buzzwords of machine learning, artificial
            intelligence and more. For the past two years, we&apos;ve been
            building the ultimate solution for both property pages and
            auto-generating property video tours.
          </li>
          <li>
            <img
              className="mx-auto"
              src={Filmstrtip}
              style={{ maxWidth: `300px` }}
            />
          </li>
          <li className="md:text-lg md:text-xl">
            All we need is property addresses. That&apos;s it. Nothing more. And
            our bots do the rest. We have an auto-scaling platform that can
            create a single property page and property video, or thousands of
            them.
          </li>
        </ul>
      </div>

      <div className="bg-purple-200 py-10 lg:py-16">
        <ul className="container grid grid-cols-1 md:grid-cols-4 gap-6">
          {[
            `You don't have to worry about <br/><span class="font-semibold">hosting.</span>`,
            `You don't have to worry about <br/><span class="font-semibold">data entry.</span>`,
            `You don't have to worry about <br/><span class="font-semibold">video post-production.</span>`,
            `You don't have to worry about <br/><span class="font-semibold">anything.</span>`,
          ].map((item, index) => (
            <li key={`worry-${index}`}>
              <IconCheck className="mx-auto mb-4 w-8 fill-current text-purple-700" />
              <div
                className="text-center text-purple-900"
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="py-10 lg:py-20 container text-center max-w-3xl">
        <h2 className="text-2xl md:text-3xl mb-8">
          It will feel like magic, but that&apos;s what new technology always
          feels like.
        </h2>
        <a
          href="https://app.realestateshows.com/listings/preview"
          className="btn btn-purple transform hover:-translate-y-1"
        >
          Why not take it for a spin?
        </a>
      </div>
    </Layout>
  )
}
export default PageBroker

export const query = graphql`
  query {
    house: file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          presentationWidth
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    automatic: file(relativePath: { eq: "automatic.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          presentationWidth
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
